import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";

const initialState = {
  contacts: undefined,

  take: 16,
  page: 1,
  skip: undefined,
  where: undefined,
  orderBy: { name: "asc" },
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts: (state, { payload }) => {
      state.contacts = payload;
    },
    setWhere: (state, { payload }) => {
      state.where = payload;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (state.page - 1) * state.take;
    },
  },
});

export const contactsApi = createApi({
  reducerPath: "contactsAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: ContactWhere, $orderBy: ContactOrderBy) {
            contacts(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              name
              email
              blocked
              username
              verified
              lastLogin
              photo {
                id
                path
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.contacts,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // SAVE LIST
          dispatch(contactsSlice.actions.setContacts([...data]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getContactsTotal: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: ContactWhere) {
            contactsTotal(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.contactsTotal,
    }),
    createContact: builder.mutation({
      query: ({ name, email, project }) => ({
        document: gql`
          mutation createContact($name: String!, $email: String!, $project: GenericConnect!) {
            createContact(name: $name, email: $email, project: $project) {
              id
              name
              email
              blocked
              username
              verified
            }
          }
        `,
        variables: {
          name,
          email,
          project: { connect: { id: project } },
        },
      }),
      transformResponse: (response) => response.createContact,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { contacts } = getState();

          // ADD TO TOP OF LIST
          dispatch(contactsSlice.actions.setContacts([{ ...data, new: true }, ...contacts?.contacts]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updateContact: builder.mutation({
      query: ({ id, name, email, blocked }) => ({
        document: gql`
          mutation updateContact($id: ID!, $name: String!, $email: String!, $blocked: Boolean) {
            updateContact(id: $id, name: $name, email: $email, blocked: $blocked) {
              id
              name
              email
              blocked
              username
              verified
              lastLogin
              photo {
                id
                path
              }
            }
          }
        `,
        variables: {
          id,
          name,
          email,
          blocked,
        },
      }),
      transformResponse: (response) => response.updateContact,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { contacts } = getState();

          // UPDATE ITEM
          const temp = [...contacts?.contacts];
          const index = temp.findIndex((e) => e.id === data.id);
          if (index !== -1) temp[index] = { ...temp[index], ...data };
          dispatch(contactsSlice.actions.setContacts([...temp]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteContact: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteContact($id: ID!) {
            deleteContact(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteContact,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { contacts } = getState();

          // REMOVE ITEM
          const temp = [...contacts?.contacts];
          const index = temp.findIndex((e) => e.id === data.id);
          if (index !== -1) temp.splice(index, 1);
          dispatch(contactsSlice.actions.setContacts([...temp]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});

export const { setContacts, setWhere, setPage, setOrderBy } = contactsSlice.actions;

export const { useGetContactsQuery, useGetContactsTotalQuery, useCreateContactMutation, useUpdateContactMutation, useDeleteContactMutation } = contactsApi;

export default contactsSlice.reducer;
