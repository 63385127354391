import { BoltIcon } from "@heroicons/react/24/outline";
import IconPlus from "app/components/atoms/icons/iconPlus";
import Loading from "app/components/atoms/loading";
import ModalPostType from "app/components/molecules/modals/modalPostType";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { useCreatePostTypeMutation, useGetPostTypesQuery } from "app/stores/builder";
import { hasPermission } from "app/utils/roles";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import PostTypeEdit from "../PostTypesEdit";

export default function PostTypes() {
  // PARAMS
  let { id } = useParams();
  const navigate = useNavigate();

  // STATE
  const [modal, setModal] = useState(null);
  const [isLeftListOpen, setLeftListOpen] = useState(true);

  // STORE
  const { user } = useSelector((store) => store.auth);
  const { project } = useSelector((store) => store.builder);

  // REQUESTS
  const {
    data: posttypes,
    isFetching,
    isLoading: isGetting,
    refetch: reloadList,
  } = useGetPostTypesQuery({
    where: {
      OR: [{ projects: { some: { id: project?.value } } }, { ...(hasPermission(user, "EXT_DEVELOPER") ? { key: "pages" } : {}) }],
    },
    orderBy: { title: "asc" },
  });
  const [createPostType, { isLoading: isCreating }] = useCreatePostTypeMutation();

  // VARS
  const isLoading = isCreating || isFetching || isGetting;

  // WHEN CREATE POST TYPE
  const onCreate = () => {
    setModal({
      isOpen: true,
      onSubmit: (data) => {
        let projects = data?.projects;
        if (!hasPermission(user, "DEVELOPER")) projects = [project?.value];

        createPostType({ ...data, projects }).then((res) => {
          if (res && res.data) {
            reloadList();
            setModal(null);
            navigate(`/dashboard/builders/post-types/${res.data.id}`);
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  // CURRENT POST TYPE
  const isCurrent = (_id) => {
    const tabNotActive = "after:w-0";
    const tabActive = "after:w-2 pl-2";
    const tabClass = "border-b relative transition-all duration-500 after:h-full after:absolute after:bg-primary-500 after:left-0 after:top-0 after:transition-all after:duration-500";
    return _id === id ? `${tabClass} ${tabActive}` : `${tabClass} ${tabNotActive}`;
  };

  // TOGGLE LEFT MENU
  const toggleLeftList = () => {
    setLeftListOpen(!isLeftListOpen);
  };

  // // TO-DO
  // const onExport = () => {};
  // // TO-DO
  // const onImport = () => {};

  return (
    <div className="flex h-full max-h-full bg-white">
      <div className={`border-r flex flex-col relative flex-1 max-h-full ${isLeftListOpen ? "max-w-[240px] lg:max-w-xs" : "max-w-0"} transition-all duration-500 ease-in-out`}>
        <button
          className="block xl:hidden absolute -right-3 top-0 bottom-0 m-auto w-fit py-[2px] px-4 h-fit rotate-90 origin-right text-sm border z-10 font-xs rounded-t-md bg-primary-900 text-white hover:bg-primary-500 transition-all duration-300 ease-in-out"
          onClick={toggleLeftList}
        >
          {isLeftListOpen ? i18n("button.hide") : i18n("button.show")}
        </button>
        <div className={`p-6 pr-2 pb-8 flex justify-between items-center transition-all duration-500 ease-in-out ${isLeftListOpen ? "w-full" : "w-0 pr-0 pl-0 overflow-hidden"}`}>
          <h1 className="font-bold text-text text-lg">{i18n("postTypes.title")}</h1>
          {/* <Dropdown
            options={[
              { title: i18n("label.import"), onClick: onImport, icon: "import" },
              { title: i18n("label.export"), onClick: onExport, icon: "export" },
            ]}
          /> */}
        </div>
        <div className="flex-1 overflow-hidden">
          {isLoading && <Loading active className="h-full flex items-center justify-center" />}
          {!isLoading && (
            <div className="max-h-full overflow-auto">
              {posttypes && posttypes.length ? (
                <ul className="border-t">
                  {posttypes.map((type) => (
                    <li className={`${isCurrent(type.id)}`} key={type.id}>
                      <Link to={`/dashboard/builders/post-types/${type.id}`} className="flex items-center">
                        <div className="block pl-6 pr-3 py-3 text-sm">{type.title}</div>
                        {type.webservice && <BoltIcon height="14px" className="text-slate-400" />}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="h-full px-6 py-3 flex items-center">
                  <span className="text-md text-slate-500 italic">{i18n("postTypes.createText")}</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`flex justify-center py-6 overflow-hidden transition-all duration-500 ease-in-out ${isLeftListOpen ? "w-auto px-4 lg:px-6" : "px-0"}`}>
          <Permission min="EXT_DEVELOPER">
            <button className="btn-primary text-xs lg:text-md" type="button" onClick={onCreate}>
              <IconPlus />
              {i18n("postTypes.button")}
            </button>
          </Permission>
        </div>
      </div>
      <div className="flex-1 bg-gray-50">
        {id ? (
          <PostTypeEdit id={id} reloadList={reloadList} />
        ) : (
          <div className="h-full w-full relative flex items-center justify-center">
            <span className="text-xl md:text-3xl xl:text-5xl text-slate-300 italic px-8">{i18n("postTypes.selectText")}</span>
          </div>
        )}
      </div>

      <ModalPostType {...modal} />
    </div>
  );
}
