import IconPlus from "app/components/atoms/icons/iconPlus";
import Loading from "app/components/atoms/loading";
import Permission from "app/components/molecules/permission";
import ListTable from "app/components/molecules/table";
import { i18n } from "app/i18n";
import { setOrderBy, setPage, useCreateContactMutation, useDeleteContactMutation, useGetContactsQuery, useGetContactsTotalQuery, useUpdateContactMutation } from "app/stores/contact";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headers } from "./constants";
import ItemContact from "./item";
import ModalContact from "app/components/molecules/modals/modalContact";
import { showServerError, showServerSuccess } from "app/utils/server";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";

export default function ListContacts() {
  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { project } = useSelector((store) => store.project);
  const { contacts, take, skip, where, orderBy } = useSelector((state) => state.contacts);

  // STATE
  const [modal, setModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // QUERIES
  const { data: total, refetch: refetchTotal } = useGetContactsTotalQuery({ where: { project: { id: project?.id } } }, { skip: !project?.id });
  const { isFetching, isLoading: isGetting, refetch: refetchList } = useGetContactsQuery({ take, skip, where: { project: { id: project?.id }, ...where }, orderBy }, { skip: !project?.id });

  // MUTATION
  const [createContact, { isLoading: isCreating }] = useCreateContactMutation();
  const [updateContact, { isLoading: isUpdating }] = useUpdateContactMutation();
  const [deleteContact, { isLoading: isDeleting }] = useDeleteContactMutation();

  // VARS
  const isLoading = isFetching || isGetting || isCreating || isUpdating || isDeleting;

  // FUNCTIONS
  function onCreate() {
    setModal({
      isOpen: true,
      project: project,
      buttonText: i18n("button.add"),
      onClose: () => setModal(null),
      onSubmit: (data) => {
        createContact({ ...data, project: project?.id }).then((res) => {
          if (res?.data) {
            setModal(null);
            refetchTotal();
            showServerSuccess(i18n("toast.success_add"));
          } else {
            showServerError(res);
          }
        });
      },
    });
  }
  function onEdit(contact) {
    setModal({
      contact,
      isOpen: true,
      project: project,
      buttonText: i18n("button.update"),
      onClose: () => setModal(null),
      onSubmit: (data) => {
        updateContact({ ...data, id: contact?.id }).then((res) => {
          if (res?.data) {
            setModal(null);
            showServerSuccess(i18n("toast.success_update"));
          } else {
            showServerError(res);
          }
        });
      },
    });
  }
  function onToggleBlock(contact) {}
  function onDelete(contact) {
    setModalConfirm({
      title: i18n("label.delete_contact"),
      type: "DELETE",
      isOpen: true,
      buttonText: i18n("button.delete"),
      onClose: () => setModalConfirm(null),
      onConfirm: () => {
        deleteContact({ id: contact?.id }).then((res) => {
          if (res?.data) {
            refetchTotal();
            showServerSuccess(i18n("toast.success_delete"));
          } else {
            showServerError(res);
          }
          setModalConfirm(null);
        });
      },
    });
  }

  return (
    <>
      <div className="custom-container">
        <div className="flex items-center justify-between pt-32 lg:pt-16 pb-10">
          <span className="flex items-center">
            <h1 className="text_3xl">{i18n("label.reserved_area")}</h1>
            <Loading active={isLoading} className="ml-3" />
          </span>
          <Permission project dev min="ADMINISTRATOR">
            <button type="button" className="btn-primary-outline" onClick={onCreate}>
              <IconPlus />
              {i18n("button.new_contact")}
            </button>
          </Permission>
        </div>
        <div>
          <ListTable headers={headers} setOrder={setOrderBy} setPage={setPage} store="user" total={total}>
            {!!contacts?.length &&
              contacts.map((contact, key) => <ItemContact key={key} {...contact} onEdit={() => onEdit(contact)} onBlock={() => onToggleBlock(contact)} onDelete={() => onDelete(contact)} />)}
          </ListTable>
        </div>
      </div>

      <ModalContact {...modal} isLoading={isLoading} />
      <ModalConfirm {...modalConfirm} isLoading={isLoading} />
    </>
  );
}
