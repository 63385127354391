import { CheckBadgeIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import Tooltip from "app/components/atoms/tooltip";
import Dropdown from "app/components/molecules/dropdown";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { hasPermission } from "app/utils/roles";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export default function ItemContact(props) {
  const { id, name, email, lastLogin, blocked, verified, onEdit, onDelete } = props;

  // STORE
  const { user: me } = useSelector((state) => state.auth);

  return (
    <tr key={id}>
      {/* NAME */}
      <td>
        <span className="leading-none">{name}</span>
        {verified && (
          <Tooltip message={i18n("label.validated")}>
            <CheckBadgeIcon className="h-4 inline text-green-400" />
          </Tooltip>
        )}
        {blocked && (
          <Tooltip message={i18n("label.blocked")}>
            <LockClosedIcon className="h-4 inline text-red-800" />
          </Tooltip>
        )}
      </td>
      {/* EMAIL */}
      <td>{email}</td>
      {/* LAST LOGIN */}
      <td>{lastLogin ? dayjs(lastLogin).format("DD/MM/YYYY HH:mm") : undefined}</td>
      <td>
        <Permission min="ADMINISTRATOR" project>
          <Dropdown
            options={[
              { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
              { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true, disabled: me?.role === "EXT_DEVELOPER" || !hasPermission(me, "ADMINISTRATOR") },
            ]}
          />
        </Permission>
      </td>
    </tr>
  );
}
