import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import React, { useEffect, useState } from "react";
import { components } from "./constants";
import { validation } from "app/utils/validators";
import { cleanStructure } from "app/utils/content";

export default function ModalContact(props) {
  const { isOpen = false, project, contact, buttonText = i18n("button.add"), onSubmit, isLoading, onClose = () => {} } = props;

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setStructure(null);
    } else {
      buildForm();
      buildStructure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const buildStructure = () => {
    const temp = cleanStructure(components);

    if (project?.id) {
      const index = temp.findIndex((e) => e.key === "email");
      if (index !== -1) {
        const indexRule = temp[index].rules.findIndex((e) => e.args);
        if (indexRule !== -1) temp[index].rules[indexRule].args.project = project.id;
      }
    }

    if (contact?.id) {
      const index = temp.findIndex((e) => e.key === "email");
      if (index !== -1) {
        const indexRule = temp[index].rules.findIndex((e) => e.args);
        if (indexRule !== -1) temp[index].rules[indexRule].args.id = contact.id;
      }
    }

    setStructure([...temp]);
  };

  const buildForm = () => {
    if (contact?.id) {
      setValue({
        name: contact.name,
        email: contact.email,
      });
    }
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSubmit(value);
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={contact ? i18n("label.edit_contact") : i18n("label.add_new_contact")} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, onClick: onClickSubmit, disabled: isLoading }} />
    </Struture>
  );
}
