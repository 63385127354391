import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const components = [
  {
    type: "Text",
    key: "name",
    title: i18n("label.name"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Text",
    key: "email",
    title: i18n("label.email"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: validator.isEmail,
        validWhen: true,
        message: i18n("input.email_format_invalid"),
      },
      {
        method: customValidator.isContactEmailAvailable,
        validWhen: true,
        args: { id: undefined, project: undefined },
        message: i18n("input.email_already_in_use"),
      },
    ],
  },
];

export { components };
