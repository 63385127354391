import { i18n } from "app/i18n";

const components = [
  {
    type: "Toggle",
    title: i18n("label.forms"),
    description: i18n("label.description.form"), // TO-DO
    key: "forms",
  },
  {
    type: "Toggle",
    title: "Translations",
    description: "Translations", // TO-DO
    key: "translations",
  },
  {
    type: "Toggle",
    title: "Newsletter",
    description: "Newsletter", // TO-DO
    key: "newsletter",
  },
  {
    type: "Toggle",
    title: "Reserved Area",
    description: "Manage users to reserved area",
    key: "reserved",
  },
  {
    type: "Toggle",
    title: "Preview",
    description: "Preview", // TO-DO
    key: "preview",
  },
  {
    type: "Text",
    title: "Preview Path",
    description: "Svalla will automatic add ?slug=[slug]&token=[token]", // TO-DO
    key: "previewPath",
    conditional: {
      field: "preview",
      when: "isTrue",
    },
  },
  {
    type: "Toggle",
    title: "Automatic Rebuild",
    description: "Automatic Rebuild", // TO-DO
    key: "autoRebuild",
  },
  {
    type: "Text",
    title: "Rebuild Path",
    description: "Svalla will automatic add ?slug=[slug]&token=[token]", // TO-DO
    key: "rebuildPath",
    conditional: {
      field: "autoRebuild",
      when: "isTrue",
    },
  },
  {
    type: "Toggle",
    title: "Rebuild Button",
    description: "Rebuild Button", // TO-DO
    key: "buttonRebuild",
  },
];

export { components };
