import { useEffect, useRef, useState } from "react";
import { i18n } from "app/i18n";

// COMPONENTS
import { Struture, Header, Content, Footer, Tabs } from "../../../atoms/modal";
import Form from "app/components/organisms/form";

// HELPERS
import { validation } from "app/utils/validators";
import { components, tabs } from "./constants";
import elements from "app/components/organisms/builder/helpers/elements";
import { cleanStructure } from "app/utils/content";
import { useGetPostTypesQuery } from "app/stores/builder";

export default function ModalComponent(props) {
  const { isOpen = false, component, inputs, inPostType, inputType, validateKey, buttonText = i18n("label.add_component"), onSubmit, isLoading, onClose = () => {} } = props;

  const firstInputRef = useRef(null);

  // STATE
  const [value, setValue] = useState(null);
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(components);

  // POST TYPES
  const { data: postTypes } = useGetPostTypesQuery({});

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setTabActive(0);
      setStructure(components);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const tempStructure = cleanStructure(components);
      // CONDITIONAL
      if (inputs && inputs.length) {
        const index = tempStructure.findIndex((item) => item.key === "conditional");
        if (index !== -1) {
          const indexInput = tempStructure[index].children.findIndex((item) => item.key === "field");
          if (indexInput !== -1) tempStructure[index].children[indexInput].options = [...inputs];
        }
      }
      // KEY
      if (typeof validateKey !== "undefined") {
        const index = tempStructure.findIndex((item) => item.key === "key");
        if (index !== -1) tempStructure[index].rules.push({ method: validateKey, validWhen: true, message: i18n("input.key_already_in_use"), temporary: true });
      }

      // FIELDS
      if (inputType) {
        const element = elements.find((el) => el.type === inputType);
        const alreadyAdded = !!tempStructure.filter((el) => el.tab === 1).length;
        if (!alreadyAdded && element?.fields?.length) {
          for (let i = 0; i < element.fields.length; i++) {
            let extraData = {};

            // ADD POSTS TO SELECT POST
            if (element.fields[i].key === "posttypes") extraData.options = postTypes.map((e) => ({ value: e.id, label: e.title }));

            tempStructure.push({ ...element.fields[i], ...extraData, temporary: true, tab: 1 });
          }
        }
        const inputTextAdded = !!tempStructure.filter((el) => el.type === "text").length;
        if (!inputTextAdded && element?.mainFields?.length) {
          for (let i = 0; i < element.mainFields.length; i++) {
            tempStructure.push({ ...element.mainFields[i], temporary: true, tab: 0 });
          }
        }
      }
      if (inPostType) {
        const index = tempStructure.findIndex((el) => el.key === "isRequired");
        if (index !== -1) {
          const input = {
            tab: 2,
            type: "Toggle",
            key: "searchable",
            title: i18n("label.searchable"),
            text: i18n("label.searchable_description"),
          };
          tempStructure.splice(index, 0, input);
        }
      }

      setTabActive(0);
      setStructure([...tempStructure]);
      if (component) setValue({ ...component });
    }
  }, [isOpen, inputs, component, inputType, inPostType]);

  useEffect(() => {
    if (firstInputRef && firstInputRef.current) firstInputRef.current.focus();
  }, [firstInputRef]);

  const deleteConditional = () => {
    const temp = value || {};
    if (temp.conditional) delete temp.conditional;
    setValue({ ...temp });
  };

  const onClickEnter = (code) => {
    if (code === 13) onClickSubmit();
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (structure.find((item) => item.error)) setTabActive(structure.find((item) => item.error).tab);
      if (validation.isValid) {
        onSubmit(value);
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={component ? i18n("label.edit_component") : i18n("label.add_new_component")} onClose={onClose} />
      <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        {tabs.map((tab, index) => (
          <div key={index}>
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} tabMode tab={tabActive} onKeyDown={onClickEnter} />
            {tab === 1 && (
              <>
                {value && value.conditional ? (
                  <button type="button" onClick={deleteConditional} className="btn-primary-outline">
                    {i18n("button.clear_conditional")}
                  </button>
                ) : null}
              </>
            )}
          </div>
        ))}
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
