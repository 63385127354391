import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Form from "app/components/organisms/form";
import { cleanStructure } from "app/utils/content";
import React, { useEffect, useState } from "react";
import { components, inputTheme } from "./constants";

export default function Button(props) {
  const { title, description, error, value, options, themes, errorMessage, isRequired, onChange } = props;

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    box: "bg-gray-50 px-5 pt-5 pb-3 rounded-lg border -m-0.5",
  };
  const [structure, setstructure] = useState(null);

  useEffect(() => {
    if (options?.length || themes?.length) {
      const temp = cleanStructure([...components]);
      if (options?.length) {
        const index = temp.findIndex((e) => e.key === "type");
        if (index !== -1) temp[index].options = temp[index].options.filter((e) => options.includes(e.value));
      }
      if (themes?.length) {
        const index = temp.findIndex((e) => e.key === "theme");
        if (index === -1) temp.push({ ...inputTheme, options: themes });
      }
      setstructure([...temp]);
    } else {
      setstructure(cleanStructure([...components]));
    }
  }, [options, themes]);

  if (error) {
    styles.title += " text-red-600";
    styles.box += " border-red-600";
  } else {
    styles.title += " text-slate-700";
    styles.box += " border-transparent";
  }

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={styles.box}>
        <Form structure={structure} value={value} onChange={onChange} mode="input-button" className="flex gap-4 items-start input-button-field" />
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
