import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";
import { IDLE, NOTHING, SUCCESS } from "app/utils/status";
import {
  addCategoriesToInfo,
  addNewPostToMenu,
  deleteCategoriesInInfo,
  removePostFromMenu,
  updateCategoriesInInfo,
  updateListOfFileCategories,
  updateProjectSettings,
  updateProjectsList,
} from "app/utils/visual";
import { inputsSlice } from "../inputs";
import { projectsSlice } from "../projects";
import { formsSlice } from "../forms";

const initialState = {
  project: null,
  posts: null,
  language: null,
  languages: null,
  users: null,
  fileCategories: null,
  translations: null,

  info: {},
  unsavedChanges: false,
  sidebarChanges: false,
  headerChanges: false,

  take: 10,
  skip: 0,
  page: 1,
  where: {},
  orderBy: { createdAt: "desc" },

  loading: false,
  postsStatus: IDLE,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, { payload }) => {
      state.project = payload;
    },
    setActiveLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setLanguages: (state, { payload }) => {
      state.languages = payload;
    },
    setFileCategories: (state, { payload }) => {
      state.fileCategories = payload;
    },
    setUsers: (state, { payload }) => {
      state.users = payload;
    },
    setTranslations: (state, { payload }) => {
      state.translations = payload;
    },
    setPosts: (state, { payload }) => {
      state.posts = payload;
    },
    setPostsStatus: (state, { payload }) => {
      state.postsStatus = payload;
    },
    setInfo: (state, { payload }) => {
      state.info = payload;
    },
    setTake: (state, { payload }) => {
      state.take = payload;
    },
    setSkip: (state, { payload }) => {
      state.skip = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (payload ? payload - 1 : 0) * state.take;
    },
    setWhere: (state, { payload }) => {
      state.where = payload;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
    setUnsavedChanges: (state, { payload }) => {
      state.unsavedChanges = payload;
    },
    setSidebarChanges: (state, { payload }) => {
      state.sidebarChanges = payload;
    },
    setHeaderChanges: (state, { payload }) => {
      state.headerChanges = payload;
    },
  },
});

export const projectApi = createApi({
  reducerPath: "projectAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getProjectInfo: builder.query({
      query: ({ id }) => ({
        document: gql`
          query ($project: String!, $id: String) {
            project(id: $project) {
              id
              url
              name
              slug
              mode
              modules
              enabled
              settings
              secretKey
              emailConfig
              information
              thumbnail {
                id
                path
              }
              languages {
                enabled
                default
                language {
                  id
                  name
                  slug
                }
              }
              forms {
                id
                unReadCount
                translations {
                  id
                  title
                  language {
                    slug
                  }
                }
              }
            }
            categories(where: { categoryType: null, project: { id: $id } }) {
              id
              icon
              thumbnail {
                id
                path
              }
              translations {
                value
                language {
                  slug
                }
              }
            }
            postTypes(where: { OR: [{ projects: { none: {} } }, { projects: { some: { id: $id } } }] }, orderBy: { createdAt: asc }) {
              id
              key
              title
              count(where: { project: { id: $id } })
              categories {
                id
                key
                icon
                type
                title
                multi
                image
                hierarchy
                options {
                  id
                  key
                  icon
                  thumbnail {
                    id
                    path
                  }
                  translations {
                    id
                    value
                    language {
                      slug
                    }
                  }
                }
              }
              posts(take: 4, where: { project: { id: $id } }, orderBy: { updatedAt: desc }) {
                id
                content {
                  title
                  slug
                  language {
                    slug
                  }
                }
              }
            }
            blocks(where: { project: { id: $id } }) {
              id
              contentType {
                id
              }
              translations {
                id
                title
                language {
                  id
                  slug
                  name
                }
              }
            }
            contentTypes(where: { OR: [{ projects: { none: {} } }, { projects: { some: { id: $id } } }] }, orderBy: { title: asc }) {
              id
              title
            }
            users(where: { OR: [{ projects: { some: { project: { id: $id } } } }, { role: SUPER }, { role: DEVELOPER }] }) {
              id
              name
              projects {
                project {
                  id
                }
              }
            }
          }
        `,
        variables: {
          id,
          project: id,
        },
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(projectSlice.actions.setProject(data?.project));
          dispatch(projectSlice.actions.setLanguages(data?.project?.languages));
          dispatch(projectSlice.actions.setUsers(data?.users));
          dispatch(projectSlice.actions.setInfo({ postTypes: data?.postTypes, contentTypes: data?.contentTypes, blocks: data?.blocks, ...data?.project?.information }));
          const activeLanguage = data?.project?.languages?.find((item) => item.default)?.language;
          dispatch(projectSlice.actions.setActiveLanguage(activeLanguage));
          dispatch(formsSlice.actions.setForms(data?.project?.forms));
          dispatch(projectSlice.actions.setTranslations(null));

          // UPDATE FILE CATEGORIES IF NECESSARY
          updateListOfFileCategories([], data?.categories, activeLanguage, projectSlice, dispatch);

          // SAVE POST TYPES FOR INPUTS
          dispatch(inputsSlice.actions.setPostTypes(data?.postTypes?.map((item) => ({ value: item.id, label: item.title, total: item.count }))));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getPostType: builder.query({
      query: ({ key }) => ({
        document: gql`
          query postTypeByKey($key: String!) {
            postTypeByKey(key: $key) {
              id
              key
              title
              structure
              singlePage
              hierarchy
              templates(list: true) {
                id
                title
              }
            }
          }
        `,
        variables: {
          key,
        },
      }),
      transformResponse: (response) => response.postTypeByKey,
    }),
    getPosts: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query posts($take: Int, $skip: Int, $where: PostWhere, $orderBy: PostOrderBy) {
            posts(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              postType {
                key
              }
              translations {
                id
                url
                slug
                title
                published
                publishedDate
                language {
                  slug
                }
                author {
                  name
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.posts,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(projectSlice.actions.setPostsStatus(data?.posts?.length ? SUCCESS : NOTHING));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getPostsTotal: builder.query({
      query: ({ where }) => ({
        document: gql`
          query postsTotal($where: PostWhere) {
            postsTotal(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.postsTotal,
    }),
    createPost: builder.mutation({
      query: ({ title, slug, type, language, project, template }) => ({
        document: gql`
          mutation createPost($title: String!, $slug: String!, $language: GenericConnect!, $project: GenericConnect!, $type: GenericConnect!, $template: GenericConnect!, $languageContent: String) {
            createPost(title: $title, slug: $slug, type: $type, language: $language, project: $project, template: $template) {
              id
              postType {
                key
              }
              content(language: $languageContent) {
                id
                title
                slug
                type
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          slug,
          title,
          type: { connect: { id: type } },
          language: { connect: { id: language } },
          project: { connect: { id: project } },
          template: { connect: { id: template } },
          languageContent: language,
        },
      }),
      transformResponse: (response) => response.createPost,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // ADD POST TO MENU
          addNewPostToMenu(project.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    duplicatePost: builder.mutation({
      query: ({ data, language, project, post }) => ({
        document: gql`
          mutation duplicatePost($data: [InputTitleAndSlug!]!, $language: GenericConnect!, $project: GenericConnect!, $post: ID!, $languageContent: String) {
            duplicatePost(data: $data, language: $language, project: $project, post: $post) {
              id
              postType {
                key
              }
              content(language: $languageContent) {
                id
                title
                slug
                type
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          post,
          data,
          project: { connect: { id: project } },
          language: { connect: { id: language } },
          languageContent: language,
        },
      }),
      transformResponse: (response) => response.duplicatePost,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // ADD POST TO MENU
          addNewPostToMenu(project.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createPostTranslation: builder.mutation({
      query: ({ post, language, title, slug, copy, origin, translate }) => ({
        document: gql`
          mutation createPostTranslation($title: String!, $slug: String!, $language: GenericConnect!, $post: GenericConnect!, $copy: Boolean!, $origin: ID, $translate: Boolean!) {
            createPostTranslation(title: $title, slug: $slug, language: $language, post: $post, copy: $copy, origin: $origin, translate: $translate) {
              id
              url
              slug
              title
              published
              publishedDate
              post {
                id
              }
              language {
                slug
              }
            }
          }
        `,
        variables: {
          slug,
          title,
          post: { connect: { id: post } },
          language: { connect: { id: language } },
          copy: copy || false,
          origin: origin || undefined,
          translate: translate || false,
        },
      }),
      transformResponse: (response) => response.createPostTranslation,
    }),
    deletePostTranslation: builder.mutation({
      query: ({ id, language, project, complete }) => ({
        document: gql`
          mutation deletePostTranslation($id: ID!, $project: ID!, $language: ID!, $complete: Boolean!) {
            deletePostTranslation(id: $id, project: $project, language: $language, complete: $complete) {
              id
            }
          }
        `,
        variables: {
          id,
          language,
          project,
          complete,
        },
      }),
      transformResponse: (response) => response.deletePostTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // ADD POST TO MENU
          removePostFromMenu(project.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    // BLOCK
    createBlock: builder.mutation({
      query: ({ title, key, language, project, contentType, copy }) => ({
        document: gql`
          mutation createBlock($title: String!, $key: String!, $language: GenericConnect!, $project: GenericConnect!, $contentType: GenericConnect!, $copy: ID) {
            createBlock(title: $title, key: $key, language: $language, project: $project, contentType: $contentType, copy: $copy) {
              id
              contentType {
                id
              }
              content {
                type
                language {
                  slug
                }
              }
              translations {
                id
                title
                language {
                  id
                  slug
                  name
                }
              }
            }
          }
        `,
        variables: {
          key,
          title,
          copy: copy?.value || undefined,
          language: { connect: { id: language } },
          project: { connect: { id: project } },
          contentType: { connect: { id: contentType.value } },
        },
      }),
      transformResponse: (response) => response.createBlock,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // ADD POST TO MENU
          addNewPostToMenu(project.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createBlockTranslation: builder.mutation({
      query: ({ block, language, title, slug, copy, origin, translate }) => ({
        document: gql`
          mutation createBlockTranslation($title: String!, $slug: String!, $language: GenericConnect!, $block: GenericConnect!, $copy: Boolean!, $origin: ID, $translate: Boolean!) {
            createBlockTranslation(title: $title, slug: $slug, language: $language, block: $block, copy: $copy, origin: $origin, translate: $translate) {
              id
              type
              title
              block {
                id
                contentType {
                  id
                }
              }
              language {
                id
                slug
                name
              }
            }
          }
        `,
        variables: {
          slug,
          title,
          block: { connect: { id: block } },
          language: { connect: { id: language } },
          copy: copy || false,
          origin: origin || undefined,
          translate: translate || false,
        },
      }),
      transformResponse: (response) => response.createBlockTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // ADD POST TO MENU
          addNewPostToMenu(project.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),

    // PROJECT
    updateProject: builder.mutation({
      query: ({ id, enabled, name, slug, thumbnail, url, mode, modules, settings, languages, emailConfig }) => ({
        document: gql`
          mutation updateProjectSettings($id: String!, $data: UpdateProjectInput!) {
            updateProjectSettings(id: $id, data: $data) {
              id
              url
              name
              slug
              mode
              modules
              enabled
              settings
              emailConfig
              thumbnail {
                id
                path
              }
              languages {
                enabled
                default
                language {
                  id
                  name
                  slug
                }
              }
            }
          }
        `,
        variables: {
          id,
          data: {
            url,
            mode,
            name,
            slug,
            enabled,
            modules,
            thumbnail,
            languages,
            emailConfig,
            settings,
          },
        },
      }),
      transformResponse: (response) => response.updateProjectSettings,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project, projects } = getState();

          // UPDATE DATA FROM CURRENT PROJECT
          updateProjectSettings(project?.project, data, projectSlice, dispatch);

          // UPDATE GLOBAL INFORMATION FROM PROJECT LIST
          updateProjectsList(projects?.projects, data, projectsSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),

    // CATEGORY
    createCategory: builder.mutation({
      query: ({ posttype, type, key, thumbnail, project, icon, translations }) => ({
        document: gql`
          mutation createCategory(
            $posttype: GenericConnect!
            $type: GenericConnect!
            $project: GenericConnect!
            $icon: String
            $key: String!
            $thumbnail: GenericConnectOrDisconnect
            $translations: [InputValueTranslation!]!
          ) {
            createCategory(posttype: $posttype, type: $type, key: $key, icon: $icon, thumbnail: $thumbnail, project: $project, translations: $translations) {
              id
              key
              icon
              thumbnail {
                id
                path
              }
              categoryType {
                id
                postType {
                  id
                }
              }
              translations {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          key,
          icon,
          translations,
          thumbnail: thumbnail || undefined,
          type: type ? { connect: { id: type } } : undefined,
          project: project ? { connect: { id: project } } : undefined,
          posttype: posttype ? { connect: { id: posttype } } : undefined,
        },
      }),
      transformResponse: (response) => response.createCategory,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // UPDATE LIST OF CATEGORIES
          addCategoriesToInfo(project?.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updateCategory: builder.mutation({
      query: ({ id, key, icon, thumbnail, translations }) => ({
        document: gql`
          mutation updateCategory($id: String!, $key: String, $icon: String, $thumbnail: GenericConnectOrDisconnect, $translations: [InputValueTranslation!]!) {
            updateCategory(id: $id, key: $key, icon: $icon, thumbnail: $thumbnail, translations: $translations) {
              id
              key
              icon
              thumbnail {
                id
                path
              }
              categoryType {
                id
                postType {
                  id
                }
              }
              translations {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          id,
          key,
          icon,
          translations,
          thumbnail: thumbnail || undefined,
        },
      }),
      transformResponse: (response) => response.updateCategory,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // UPDATE LIST OF CATEGORIES
          updateCategoriesInInfo(project?.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteCategory: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteCategory($id: String!) {
            deleteCategory(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteCategory,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project } = getState();

          // DELETE FROM LIST OF CATEGORIES
          deleteCategoriesInInfo(project?.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),

    // SITEMAP
    getSitemap: builder.query({
      query: ({ id }) => ({
        document: gql`
          query ($id: String!) {
            getSitemap(id: $id)
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.getSitemap,
    }),
    refreshSitemap: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          query ($id: String!) {
            buildSitemap(id: $id)
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.buildSitemap,
    }),
  }),
});

export const { setProject, setLanguages, setUsers, setPosts, setPostsStatus, setUnsavedChanges, setSidebarChanges, setHeaderChanges, setTake, setSkip, setWhere, setOrderBy, setPage } =
  projectSlice.actions;

export const {
  useGetProjectInfoQuery,
  useGetPostTypeQuery,
  useGetPostsQuery,
  useGetPostsTotalQuery,
  useCreatePostMutation,
  useDuplicatePostMutation,
  useCreatePostTranslationMutation,
  useUpdateProjectMutation,
  useDeletePostTranslationMutation,

  useCreateBlockMutation,
  useCreateBlockTranslationMutation,

  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,

  useGetSitemapQuery,
  useRefreshSitemapMutation,
} = projectApi;

export default projectSlice.reducer;
